import $, { Component, Wrapper } from "untrue";

import DocumentContext from "../../context/DocumentContext";

import Figure from "./Figure/Figure";

class FigureWrapper extends Component {
  constructor(props) {
    super(props);

    this.state = { index: 0 };
  }

  onIndex = (index) => {
    this.updateState({ index });
  };

  onNext = () => {
    this.onIndex(this.state.index + 1);
  };

  onPrev = () => {
    this.onIndex(this.state.index - 1);
  };

  render() {
    const { index } = this.state;

    const { liked, figureIds, figuresCount, widths, heights } = this.props;

    return $(null, {}, [
      $(
        "div",
        {
          class: "w-100% overflow-hidden",
          style: `aspect-ratio: ${widths[index] / heights[index]}`,
        },
        $(
          "div",
          {
            class: "white-space-nowrap vertical-align-top transition-all-300ms",
            style: `transform: translateX(${-index * 100}%)`,
          },
          figureIds.map((figureId, i) =>
            $(
              "div",
              {
                key: figureId,
                class: "inline-block vertical-align-top w-100%",
              },
              $(Figure, {
                figureId,
                index,
                active: i === index,
                figuresCount,
                onNext: this.onNext,
                onPrev: this.onPrev,
              })
            )
          )
        )
      ),
      figuresCount > 1
        ? $(
            "div",
            { class: "flex justify-center" },
            figureIds.map((figureId, i) =>
              $(
                "button",
                {
                  key: figureId,
                  class: "px-5 py-10",
                  onclick: () => this.onIndex(i),
                },
                $(
                  "div",
                  {
                    class: `relative bg-color-secondary-50 w-8 h-8 rounded-100% transition-all-300ms ${
                      i === index ? "scale-1.25" : ""
                    }`,
                  },
                  $("div", {
                    class: `absolute inset-0 bg-color-primary-100 rounded-100% transition-all-300ms ${
                      i === index ? "opacity-100%" : "opacity-0%"
                    }`,
                  })
                )
              )
            )
          )
        : null,
    ]);
  }
}

export default Wrapper.wrapContext(
  FigureWrapper,
  DocumentContext,
  ({ postId }) => {
    const documents = DocumentContext.getDocuments();

    const { liked, figures: figureIds } = documents.Post[postId];

    const figuresCount = figureIds.length;

    const figures = figureIds.map((figureId) => documents.PostFigure[figureId]);

    const mediaIds = figures.map((figure) => figure.media);

    const medias = mediaIds.map((mediaId) => documents.Media[mediaId]);

    const widths = medias.map((media) => media.width);

    const heights = medias.map((media) => media.height);

    return { liked, figureIds, figuresCount, widths, heights };
  }
);
