"use strict";
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Slot = void 0;
const Component_1 = require("./Component");
function $(contentType, ...args) {
    let attributes = null;
    let children = [];
    switch (args.length) {
        case 0: {
            break;
        }
        case 1: {
            if (Array.isArray(args[0])) {
                children = args[0];
            }
            else if (args[0] instanceof Slot) {
                children = [args[0]];
            }
            else if (args[0] !== null && typeof args[0] === "object") {
                attributes = args[0];
            }
            else {
                children = [args[0]];
            }
            break;
        }
        default: {
            attributes = args[0];
            children = Array.isArray(args[1]) ? args[1] : [args[1]];
            break;
        }
    }
    const tmpContentType = contentType;
    const isClass = typeof tmpContentType === "function" &&
        /^class\s/.test(Function.prototype.toString.call(tmpContentType));
    if (!(tmpContentType === null ||
        (isClass &&
            (tmpContentType.prototype === Component_1.Component ||
                tmpContentType.prototype instanceof Component_1.Component)) ||
        (!isClass && typeof tmpContentType === "function") ||
        typeof tmpContentType === "string")) {
        throw new Error("Content type must be a Component class, function, string or null.");
    }
    if (typeof attributes !== "object" || Array.isArray(attributes)) {
        throw new Error("Attributes must be object or null.");
    }
    return new Slot(contentType, attributes, children);
}
exports.default = $;
class Slot {
    constructor(contentType, attributes, children) {
        this.propsChildren = null;
        children = Slot.parseChildren(children);
        const tmpContentType = contentType;
        if (tmpContentType !== null &&
            (tmpContentType.prototype === Component_1.Component ||
                tmpContentType.prototype instanceof Component_1.Component ||
                typeof tmpContentType === "function")) {
            this.propsChildren = children;
            children = [];
        }
        this.contentType = contentType;
        this.attributes = attributes;
        this.children = children;
    }
    getContentType() {
        return this.contentType;
    }
    getAttributes() {
        return this.attributes;
    }
    getChildren() {
        return this.children;
    }
    setChildren(children) {
        let tmpChildren = Array.isArray(children) ? children : [children];
        tmpChildren = Slot.parseChildren(tmpChildren);
        this.children = tmpChildren;
    }
    isComponent() {
        const tmpContentType = this.contentType;
        return (tmpContentType !== null &&
            (tmpContentType.prototype === Component_1.Component ||
                tmpContentType.prototype instanceof Component_1.Component));
    }
    isFunction() {
        return typeof this.contentType === "function" && !this.isComponent();
    }
    isElement() {
        return typeof this.contentType === "string";
    }
    isNull() {
        return this.contentType === null;
    }
    getKey() {
        if (this.attributes === null) {
            return null;
        }
        const { key = null } = this.attributes;
        return key;
    }
    getRef() {
        if (this.attributes === null) {
            return null;
        }
        const { ref = null } = this.attributes;
        return ref;
    }
    getProps() {
        var _a, _b;
        const _c = (_a = this.attributes) !== null && _a !== void 0 ? _a : {}, { key, ref } = _c, tmpAttributes = __rest(_c, ["key", "ref"]);
        const children = (_b = this.propsChildren) !== null && _b !== void 0 ? _b : [];
        const props = Object.assign(Object.assign({}, tmpAttributes), { children });
        return props;
    }
    static parseChildren(children) {
        return children.map((child, i) => {
            if (child instanceof Slot) {
                const key = child.getKey();
                if (key !== null) {
                    const index = children.findIndex((tmpChild) => {
                        if (!(tmpChild instanceof Slot)) {
                            return false;
                        }
                        const tmpKey = tmpChild.getKey();
                        return key === tmpKey;
                    });
                    if (index !== i) {
                        throw new Error(`Repeated keys: ${key}`);
                    }
                }
            }
            return Array.isArray(child) ? new Slot(null, null, child) : child;
        });
    }
}
exports.Slot = Slot;
