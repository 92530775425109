"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Tree = void 0;
const untrue_1 = require("untrue");
const Target_1 = require("./Target");
const Edge_1 = require("./Edge");
const StackItem_1 = require("./StackItem");
const ErrorHandler_1 = require("../ErrorHandler");
class Tree {
    constructor(node) {
        this.node = node;
        this.edge = null;
        this.stack = [];
    }
    mount(slot) {
        if (this.edge !== null) {
            this.unmount();
        }
        const target = new Target_1.Target(this.node);
        this.edge = new Edge_1.Edge(slot);
        this.renderEdge(this.edge, null, target);
    }
    unmount() {
        if (this.edge === null) {
            return;
        }
        const target = new Target_1.Target(this.node);
        this.unmountEdge(this.edge, target);
        this.edge = null;
        this.stack = [];
        clearTimeout(this.timeout);
    }
    queue(edge, node) {
        const item = new StackItem_1.StackItem(edge, node);
        this.stack.push(item);
        clearTimeout(this.timeout);
        this.timeout = setTimeout(() => {
            this.rerender();
        });
    }
    unqueue(edge) {
        this.stack = this.stack.filter((item) => item.edge.component !== edge.component);
    }
    rerender() {
        if (this.stack.length === 0) {
            return;
        }
        this.stack.sort((a, b) => a.edge.depth - b.edge.depth);
        const item = this.stack[0];
        const edge = item.edge;
        const node = item.node;
        const currentEdge = edge.clone();
        const index = this.findTargetIndex(edge, node);
        const target = new Target_1.Target(node, index);
        this.renderEdge(edge, currentEdge, target);
        this.rerender();
    }
    createChildren(edge) {
        const slot = edge.slot;
        const children = slot instanceof untrue_1.Slot ? slot.getChildren() : [];
        const edges = children.map((child) => new Edge_1.Edge(child, edge, edge.depth + 1));
        edge.children = edges;
    }
    renderChildren(edge, currentEdge, target) {
        var _a;
        this.createChildren(edge);
        const children = edge.children;
        const currentChildren = (_a = currentEdge === null || currentEdge === void 0 ? void 0 : currentEdge.children) !== null && _a !== void 0 ? _a : [];
        for (let i = 0; i < currentChildren.length; i++) {
            const currentChild = currentChildren[i];
            let child = null;
            const currentSlot = currentChild.slot;
            if (currentSlot instanceof untrue_1.Slot && currentSlot.getKey() !== null) {
                for (const tmpChild of children) {
                    if (this.isEqual(currentChild, tmpChild)) {
                        child = tmpChild;
                        break;
                    }
                }
            }
            if (child === null && i < children.length) {
                const tmpChild = children[i];
                if (this.isEqual(currentChild, tmpChild)) {
                    child = tmpChild;
                }
            }
            if (child === null) {
                this.unmountEdge(currentChild, target);
            }
        }
        for (let i = 0; i < children.length; i++) {
            const child = children[i];
            let currentChild = null;
            const slot = child.slot;
            if (slot instanceof untrue_1.Slot && slot.getKey() !== null) {
                for (const tmpChild of currentChildren) {
                    if (this.isEqual(child, tmpChild)) {
                        currentChild = tmpChild;
                        break;
                    }
                }
            }
            if (currentChild === null && i < currentChildren.length) {
                const tmpChild = currentChildren[i];
                if (this.isEqual(child, tmpChild)) {
                    currentChild = tmpChild;
                }
            }
            this.renderEdge(child, currentChild, target);
        }
    }
    renderEdge(edge, currentEdge, target) {
        const slot = edge.slot;
        if (currentEdge !== null) {
            edge.children = currentEdge.children;
        }
        try {
            if (slot instanceof untrue_1.Slot) {
                if (slot.isComponent()) {
                    this.renderComponent(edge, currentEdge, target);
                }
                else if (slot.isFunction()) {
                    this.renderFunction(edge, currentEdge, target);
                }
                else if (slot.isElement()) {
                    this.renderElement(edge, currentEdge, target);
                }
                else if (slot.isNull()) {
                    this.renderNull(edge, currentEdge, target);
                }
            }
            else if (slot !== null && slot !== undefined && slot !== false) {
                this.renderText(edge, currentEdge, target);
            }
        }
        catch (error) {
            ErrorHandler_1.ErrorHandler.handle(error);
        }
    }
    renderComponent(edge, currentEdge, target) {
        var _a, _b, _c;
        const slot = edge.slot;
        const currentSlot = (_a = currentEdge === null || currentEdge === void 0 ? void 0 : currentEdge.slot) !== null && _a !== void 0 ? _a : null;
        const contentType = slot.getContentType();
        const props = slot.getProps();
        let component = (_b = currentEdge === null || currentEdge === void 0 ? void 0 : currentEdge.component) !== null && _b !== void 0 ? _b : null;
        if (component === null) {
            const ComponentClass = contentType;
            component = new ComponentClass(props);
            component.init();
        }
        else {
            component.updateProps(props);
        }
        edge.component = component;
        this.unqueue(edge);
        const ref = slot.getRef();
        const currentRef = (_c = currentSlot === null || currentSlot === void 0 ? void 0 : currentSlot.getRef()) !== null && _c !== void 0 ? _c : null;
        if (currentRef instanceof untrue_1.Ref && currentRef !== ref) {
            currentRef.current = null;
        }
        if (ref instanceof untrue_1.Ref && ref !== currentRef) {
            ref.current = component;
        }
        const children = component.render();
        slot.setChildren(children);
        this.renderChildren(edge, currentEdge, target);
        component.triggerRender(() => {
            this.queue(edge, target.node);
        });
    }
    renderFunction(edge, currentEdge, target) {
        const slot = edge.slot;
        const contentType = slot.getContentType();
        const props = slot.getProps();
        const ComponentFunction = contentType;
        const children = ComponentFunction(props);
        slot.setChildren(children);
        this.renderChildren(edge, currentEdge, target);
    }
    renderElement(edge, currentEdge, target) {
        var _a;
        let node = (_a = currentEdge === null || currentEdge === void 0 ? void 0 : currentEdge.node) !== null && _a !== void 0 ? _a : null;
        if (node === null) {
            node = this.createNode(edge);
        }
        edge.node = node;
        this.patchNode(edge, currentEdge);
        const tmpTarget = new Target_1.Target(node);
        this.renderChildren(edge, currentEdge, tmpTarget);
        target.insert(node);
    }
    renderText(edge, currentEdge, target) {
        var _a;
        let node = (_a = currentEdge === null || currentEdge === void 0 ? void 0 : currentEdge.node) !== null && _a !== void 0 ? _a : null;
        if (node === null) {
            node = this.createNode(edge);
        }
        edge.node = node;
        this.patchNode(edge, currentEdge);
        target.insert(node);
    }
    renderNull(edge, currentEdge, target) {
        this.renderChildren(edge, currentEdge, target);
    }
    unmountEdge(edge, target) {
        const slot = edge.slot;
        const node = edge.node;
        const component = edge.component;
        const children = edge.children;
        let tmpTarget = target;
        if (node !== null) {
            target.remove(node);
            if (slot instanceof untrue_1.Slot && slot.isElement()) {
                tmpTarget = new Target_1.Target(node);
            }
        }
        const ref = slot instanceof untrue_1.Slot ? slot.getRef() : null;
        if (ref instanceof untrue_1.Ref) {
            ref.current = null;
        }
        for (const child of children) {
            this.unmountEdge(child, tmpTarget);
        }
        if (component !== null) {
            this.unqueue(edge);
            component.triggerUnmount();
        }
    }
    isEqual(edge, currentEdge) {
        const slot = edge.slot;
        const currentSlot = currentEdge.slot;
        if (slot instanceof untrue_1.Slot) {
            if (!(currentSlot instanceof untrue_1.Slot)) {
                return false;
            }
            const contentType = slot.getContentType();
            const key = slot.getKey();
            const currentContentType = currentSlot.getContentType();
            const currentKey = currentSlot.getKey();
            return contentType === currentContentType && key === currentKey;
        }
        if (slot === null || slot === undefined || slot === false) {
            return (currentSlot === null ||
                currentSlot === undefined ||
                currentSlot === false);
        }
        return (currentSlot !== null &&
            currentSlot !== undefined &&
            currentSlot !== false &&
            !(currentSlot instanceof untrue_1.Slot));
    }
    createNode(edge) {
        const slot = edge.slot;
        if (slot instanceof untrue_1.Slot) {
            const contentType = slot.getContentType();
            const tagName = contentType;
            return document.createElement(tagName);
        }
        else {
            return document.createTextNode("");
        }
    }
    patchNode(edge, currentEdge) {
        var _a, _b, _c, _d, _e, _f;
        const slot = edge.slot;
        const node = edge.node;
        const currentSlot = (_a = currentEdge === null || currentEdge === void 0 ? void 0 : currentEdge.slot) !== null && _a !== void 0 ? _a : null;
        if (slot instanceof untrue_1.Slot) {
            const element = node;
            const attributes = (_b = slot.getAttributes()) !== null && _b !== void 0 ? _b : {};
            const currentAttributes = (_c = currentSlot === null || currentSlot === void 0 ? void 0 : currentSlot.getAttributes()) !== null && _c !== void 0 ? _c : {};
            for (const key in attributes) {
                const value = (_d = attributes[key]) !== null && _d !== void 0 ? _d : null;
                const currentValue = (_e = currentAttributes[key]) !== null && _e !== void 0 ? _e : null;
                switch (key) {
                    case "key": {
                        break;
                    }
                    case "ref": {
                        const ref = value;
                        const currentRef = currentValue;
                        if (currentRef instanceof untrue_1.Ref && currentRef !== ref) {
                            currentRef.current = null;
                        }
                        if (ref instanceof untrue_1.Ref && ref !== currentRef) {
                            ref.current = element;
                        }
                        break;
                    }
                    default: {
                        const isValueHandler = typeof value === "function";
                        const isCurrentValueHandler = typeof currentValue === "function";
                        if (value !== null) {
                            if (isValueHandler) {
                                if (currentValue !== null && !isCurrentValueHandler) {
                                    element.removeAttribute(key);
                                }
                                if (value !== currentValue) {
                                    element[key] = value;
                                }
                            }
                            else {
                                if (currentValue !== null && isCurrentValueHandler) {
                                    element[key] = null;
                                }
                                if (value !== currentValue) {
                                    try {
                                        element.setAttribute(key, value);
                                    }
                                    catch (error) {
                                        ErrorHandler_1.ErrorHandler.handle(error);
                                    }
                                }
                            }
                        }
                        else {
                            if (currentValue !== null) {
                                if (isCurrentValueHandler) {
                                    element[key] = null;
                                }
                                else {
                                    element.removeAttribute(key);
                                }
                            }
                        }
                        break;
                    }
                }
            }
            for (const key in currentAttributes) {
                const found = key in attributes;
                if (found) {
                    continue;
                }
                const currentValue = (_f = currentAttributes[key]) !== null && _f !== void 0 ? _f : null;
                switch (key) {
                    case "ref": {
                        const currentRef = currentValue;
                        if (currentRef instanceof untrue_1.Ref) {
                            currentRef.current = null;
                        }
                    }
                    default: {
                        const isCurrentValueHandler = typeof currentValue === "function";
                        if (currentValue !== null) {
                            if (isCurrentValueHandler) {
                                element[key] = null;
                            }
                            else {
                                element.removeAttribute(key);
                            }
                        }
                    }
                }
            }
        }
        else {
            const text = node;
            const value = `${slot}`;
            const currentValue = currentSlot !== null ? `${currentSlot}` : null;
            if (value !== currentValue) {
                text.nodeValue = value;
            }
        }
    }
    findTargetIndex(edge, targetNode) {
        const parent = edge.parent;
        if (parent === null) {
            return 0;
        }
        const node = parent.node;
        const children = parent.children;
        const index = children.indexOf(edge);
        for (let i = index - 1; i >= 0; i--) {
            const child = children[i];
            const j = this.findNodeIndex(child, targetNode);
            if (j !== null) {
                return j + 1;
            }
        }
        if (node === targetNode) {
            return 0;
        }
        return this.findTargetIndex(parent, targetNode);
    }
    findNodeIndex(edge, targetNode) {
        const node = edge.node;
        const children = edge.children;
        if (node !== null) {
            const childNodes = [...targetNode.childNodes];
            return childNodes.indexOf(node);
        }
        for (let i = children.length - 1; i >= 0; i--) {
            const child = children[i];
            const index = this.findNodeIndex(child, targetNode);
            if (index !== null) {
                return index;
            }
        }
        return null;
    }
}
exports.Tree = Tree;
