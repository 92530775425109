"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Context = void 0;
const Stateful_1 = require("./Stateful");
class Context extends Stateful_1.Stateful {
    constructor() {
        super();
        queueMicrotask(() => {
            this.init();
        });
    }
    persist() {
        const value = this.state;
        return value;
    }
    hydrate(value) {
        this.state = value;
    }
    startUpdate() {
        this.performUpdate();
    }
    performUpdate() {
        super.performUpdate();
        this.triggerUpdate();
    }
}
exports.Context = Context;
