import $, { Wrapper } from "untrue";

import DocumentContext from "../../context/DocumentContext";

import RichText from "../RichText";

function Text({ text, mentionIds }) {
  return $(RichText, {
    linkStyle: "color-primary-100 hover:text-decoration-underline",
    text,
    mentionIds,
  });
}

export default Wrapper.wrapContext(Text, DocumentContext, ({ postId }) => {
  const documents = DocumentContext.getDocuments();

  const { text, mentions: mentionIds } = documents.Post[postId];

  return { text, mentionIds };
});
