"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Animation = void 0;
const Emitter_1 = require("../Emitter");
const Transition_1 = require("./Transition");
class Animation extends Emitter_1.Emitter {
    constructor(value) {
        super();
        this.value = value;
        this.transition = null;
    }
    getValue() {
        return this.value;
    }
    updateValue(value) {
        this.value = value;
        this.emit("update");
    }
    bind(component, listener) {
        component.on("render", listener);
        component.on("mount", () => {
            this.on("update", listener);
        });
        component.on("unmount", () => {
            this.off("update", listener);
        });
    }
    animate(value, time, easing = null) {
        if (this.transition !== null) {
            this.transition.cancel();
        }
        const transition = new Transition_1.Transition(this, value, time, easing);
        setTimeout(() => {
            transition.start();
        });
        this.transition = transition;
        return this.transition;
    }
    split(item) {
        let num = 0;
        let unit = null;
        const regex = /^(\d*\.\d+|\d+)([a-zA-Z%]+)?$/;
        const match = item.match(regex);
        if (match === null) {
            throw new Error("Invalid output item.");
        }
        num = parseFloat(match[1]);
        if (match[2] !== undefined) {
            unit = match[2];
        }
        return { num, unit };
    }
    interpolate(input, output) {
        if (input.length < 2) {
            throw new Error("Input needs at least two items.");
        }
        if (input.length !== output.length) {
            throw new Error("Input and output must have the same number of items.");
        }
        const checkRepeated = input.every((item, i) => input.indexOf(item) === i);
        if (!checkRepeated) {
            throw new Error("Input must not have repeated items.");
        }
        const checkAscending = input.every((item, i) => i !== 0 ? item > input[i - 1] : true);
        if (!checkAscending) {
            throw new Error("Input must be in ascending order.");
        }
        const types = output.map((item) => typeof item);
        const checkTypes = types.every((type) => type === types[0]);
        if (!checkTypes) {
            throw new Error("Output must be an array of strings or an array of numbers.");
        }
        const isOutputStringArray = types[0] === "string";
        const items = output.map((item) => {
            return isOutputStringArray
                ? this.split(item)
                : { num: item, unit: null };
        });
        const checkUnits = items.every((item) => item.unit === items[0].unit);
        if (!checkUnits) {
            throw new Error("All output units must be the same.");
        }
        const nums = items.map((item) => item.num);
        let num;
        const unit = items[0].unit;
        const inputIndex = input.indexOf(this.value);
        if (inputIndex !== -1) {
            num = nums[inputIndex];
        }
        else {
            let currentInput;
            let nextInput;
            let currentNum;
            let nextNum;
            const lastInputIndex = input.length - 1;
            if (this.value > input[lastInputIndex]) {
                const index = lastInputIndex;
                currentInput = input[index];
                nextInput = input[index] + (input[index] - input[index - 1]);
                currentNum = nums[index];
                nextNum = nums[index] + (nums[index] - nums[index - 1]);
            }
            else {
                let index = 0;
                for (let i = 0; i < input.length; i++) {
                    if (input[i] > this.value) {
                        break;
                    }
                    index = i;
                }
                currentInput = input[index];
                nextInput = input[index + 1];
                currentNum = nums[index];
                nextNum = nums[index + 1];
            }
            const inputDifference = nextInput - currentInput;
            const numDifference = nextNum - currentNum;
            const valueDifference = this.value - currentInput;
            const offset = (valueDifference * numDifference) / inputDifference;
            num = currentNum + offset;
        }
        if (isOutputStringArray) {
            return `${num}${unit !== null && unit !== void 0 ? unit : ""}`;
        }
        return num;
    }
}
exports.Animation = Animation;
