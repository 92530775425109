import { Context } from "untrue";

import CookieHelper from "../helpers/CookieHelper";

import { initClient } from "../client";

class AuthContext extends Context {
  constructor() {
    super();

    this.state = { isAuth: false, loggedId: null };
  }

  hydrate(state) {
    const { isAuth } = state;

    if (isAuth) {
      const token = CookieHelper.getCookie("token");

      if (token === null) {
        return;
      }
    }

    return super.hydrate(state);
  }

  async login(authResponse) {
    const {
      user: { id: userId },
      token,
    } = authResponse;

    await this.updateState({ isAuth: true, loggedId: userId });

    CookieHelper.setCookie("token", token);

    initClient();
  }

  async logout() {
    await this.updateState({ isAuth: false, loggedId: null });

    CookieHelper.deleteCookie("token");

    initClient();
  }
}

export default new AuthContext();
