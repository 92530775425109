import $ from "untrue";

import TranslationContext from "../../../../../context/TranslationContext";

function Error({ onRetry }) {
  return $("div", { class: "flex-1 flex flex-column align-center py-20" }, [
    $(
      "span",
      { class: "text-center color-secondary-60 mb-15" },
      TranslationContext.getData().template.auth.header.search.error
    ),
    $(
      "button",
      {
        class:
          "bg-color-primary-100 color-white px-10 py-5 rounded-6 hover:bg-color-primary-light-100",
        onclick: onRetry,
      },
      TranslationContext.getData().buttons.retry
    ),
  ]);
}

export default Error;
