"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Stateful = void 0;
const Comparer_1 = require("./Comparer");
const Emitter_1 = require("./Emitter");
class Stateful extends Emitter_1.Emitter {
    constructor() {
        super(...arguments);
        this.prevState = null;
        this.nextState = null;
        this.updateResolvers = [];
    }
    init() { }
    getState() {
        return this.state;
    }
    triggerUpdate() {
        const self = this;
        self.emit("update");
    }
    update() {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.queueUpdate();
        });
    }
    updateState(state) {
        return __awaiter(this, void 0, void 0, function* () {
            let currentState = Object.assign({}, this.state);
            if (this.nextState !== null) {
                currentState = Object.assign(Object.assign({}, currentState), this.nextState);
            }
            let tmpState = Object.assign(Object.assign({}, currentState), state);
            if (this.nextState === null) {
                const equal = Comparer_1.Comparer.compare(tmpState, currentState);
                if (equal) {
                    return;
                }
            }
            this.nextState = tmpState;
            return yield this.queueUpdate();
        });
    }
    queueUpdate() {
        clearTimeout(this.updateTimeout);
        this.updateTimeout = setTimeout(() => {
            this.startUpdate();
        });
        return new Promise((resolve) => {
            this.updateResolvers.push(resolve);
        });
    }
    performUpdate() {
        this.replaceUpdate();
        this.resolveUpdate();
    }
    replaceUpdate() {
        this.prevState = this.state;
        if (this.nextState !== null) {
            this.state = this.nextState;
        }
        this.nextState = null;
    }
    resolveUpdate() {
        this.updateResolvers.forEach((resolve) => {
            resolve();
        });
        this.updateResolvers = [];
    }
}
exports.Stateful = Stateful;
