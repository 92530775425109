"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Edge = void 0;
class Edge {
    constructor(slot, parent = null, depth = 0, children = [], component = null, node = null) {
        this.slot = slot;
        this.parent = parent;
        this.depth = depth;
        this.children = children;
        this.component = component;
        this.node = node;
    }
    clone() {
        return new Edge(this.slot, this.parent, this.depth, this.children, this.component, this.node);
    }
}
exports.Edge = Edge;
