import $, { Animation, Component, Ref, Wrapper } from "untrue";

import ViewMediaModalContext from "../../context/ViewMediaModalContext";

import Button from "./Button";
import Image from "./Image";
import Video from "./Video";

class ViewMediaModal extends Component {
  constructor(props) {
    super(props);

    this.modalRef = new Ref();
    this.closeButtonRef = new Ref();

    this.showAnimation = new Animation(0);

    this.showAnimation.bind(this, this.handleShowAnimation);

    this.on("mount", this.handleMountAnimation);

    this.on("mount", this.handleMountWindow);
    this.on("unmount", this.handleUnmountWindow);
  }

  handleMountWindow = () => {
    document.body.style.overflow = "hidden";
  };

  handleUnmountWindow = () => {
    document.body.removeAttribute("style");
  };

  handleMountAnimation = () => {
    this.onOpen();
  };

  handleShowAnimation = () => {
    const modal = this.modalRef.current;
    const closeButton = this.closeButtonRef.current;

    if (modal === null) {
      return;
    }

    if (closeButton === null) {
      return;
    }

    modal.style.opacity = this.showAnimation.interpolate(
      [0, 1],
      ["0%", "100%"]
    );

    closeButton.style.transform = `translateX(${this.showAnimation.interpolate(
      [0, 1],
      ["100%", "0%"]
    )})`;
  };

  onOpen = () => {
    this.showAnimation.animate(1, 250);
  };

  onClose = () => {
    const transition = this.showAnimation.animate(0, 250);

    transition.on("end", () => {
      ViewMediaModalContext.clear();
    });
  };

  render() {
    const { item } = this.props;

    const { source, type, duration } = item;

    return $(
      "div",
      {
        ref: this.modalRef,
        class:
          "fixed z-20 inset-0 flex justify-center align-center p-60 bg-color-black absolute inset-0",
      },
      [
        type === "image"
          ? $(Image, { source })
          : $(Video, { source, duration }),
        $(
          "div",
          {
            ref: this.closeButtonRef,
            class: "absolute top-5 right-5",
            onclick: this.onClose,
          },
          $(Button, { icon: "fa-xmark", onClick: this.onClose })
        ),
      ]
    );
  }
}

function ViewMediaModalContainer({ item, ...props }) {
  if (item === null) {
    return null;
  }

  return $(ViewMediaModal, { ...props, item });
}

export default Wrapper.wrapContext(
  ViewMediaModalContainer,
  ViewMediaModalContext,
  () => {
    const { item } = ViewMediaModalContext.getState();

    return { item };
  }
);
